import Script from 'next/script';

const InitializeSasic = () => (
  <>
    <script
      dangerouslySetInnerHTML={{
        __html: `
          window._sasic_queue = window._sasic_queue || [];
        `,
      }}
    />
    <Script
      strategy="afterInteractive"
      src="https://prod-snowly-sasic.stdout.cz/dist/economia/sasic.min.js"
      onLoad={() => window._sasic.init()}
    />
  </>
);

export default InitializeSasic;
