import Script from 'next/script';

const InitializeLoggly = () => (
  <>
    <script
      dangerouslySetInnerHTML={{
        __html: `
          window.AlsOptions = window.AlsOptions || { tag: 'pujdu' };
        `,
      }}
    />
    <Script
      src="https://prod-snowly-als.stdout.cz/dist/als.min.js"
      async
      onLoad={() => window._feLogging.initLogging()}
    />
  </>
);

export default InitializeLoggly;
