import { useState } from 'react';

import {
  Hydrate,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import type { AppProps } from 'next/app';
import 'react-toastify/dist/ReactToastify.min.css';
import '@styles/globals.css';
import Head from 'next/head';
import { YbugProvider } from 'ybug-react';

import InitializeLoggly from '@adverts/components/InitializeLoggly';
import InitializeSasic from '@adverts/components/InitializeSasic';
import useConsent from '@adverts/hooks/useConsent';
import {
  InitializeGtmHead,
  InitializeGtmNoScript,
} from '@analytics/components/InitializeGtm';
import Toastify from '@popup/components/Toastify';

const App = ({ Component, pageProps }: AppProps) => {
  const [queryClient] = useState(() => new QueryClient());

  useConsent();

  return (
    <>
      <Head>
        <meta charSet="UTF-8" key="charset" />
        <link rel="manifest" href="/site.webmanifest" />
        <meta name="msapplication-TileColor" content="#fff" />
        <meta name="theme-color" content="#ffffff" />
        <InitializeGtmHead />
      </Head>
      <InitializeGtmNoScript />
      <InitializeSasic />
      <InitializeLoggly />
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <Hydrate state={pageProps.dehydratedState}>
          <YbugProvider ybugId="c14c89c7zcj406cs782z">
            <Component {...pageProps} />
            <Toastify />
          </YbugProvider>
        </Hydrate>
      </QueryClientProvider>
    </>
  );
};

export default App;
