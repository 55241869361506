const gtmId = 'N3T8G5C' as const;
const gtmScript =
  `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-${gtmId}');` as const;
const gtmIframeHtml =
  `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-${gtmId}" height="0" width="0" style="display:none;visibility:hidden"></iframe>` as const;

export const InitializeGtmHead = () => (
  <script
    dangerouslySetInnerHTML={{
      __html: gtmScript,
    }}
  />
);

export const InitializeGtmNoScript = () => (
  <noscript
    dangerouslySetInnerHTML={{
      __html: gtmIframeHtml,
    }}
  />
);
