import { ToastContainer } from 'react-toastify';

const Toastify = () => (
  <ToastContainer
    autoClose={2000}
    closeButton={false}
    hideProgressBar={true}
    position="bottom-center"
    className="bottom-20 flex w-[calc(100%-16px)] max-w-[880px] flex-wrap"
    bodyClassName="flex p-0 flex-wrap items-center justify-center shadow-popup rounded-[40px] w-full"
    toastClassName="p-0 rounded-[40px] bg-brand-transparent-100 w-full"
  />
);

export default Toastify;
