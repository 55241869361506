import { useEffect } from 'react';

import { initializeCmpConsent } from '@adverts/initializeCmpConsent';

const useConsent = () => {
  useEffect(() => {
    initializeCmpConsent();
  }, []);
};

export default useConsent;
